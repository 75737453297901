import React, { useState, useEffect } from 'react';
import '../documents.scss';

export default function LikumiIUnDocs() {
  const [selectedUrl, setSelectedUrl] = useState('');

  const links = [
    {
      label: 'Sporta likums',
      url: 'https://m.likumi.lv/doc.php?id=68294',
    },
    {
      label: 'Sporta speciālists',
      url: 'https://m.likumi.lv/doc.php?id=204329&from=off',
    },
  ];

  useEffect(() => {
    // Загружаем первую ссылку автоматически
    setSelectedUrl(links[0].url);
  }, []);

  return (
    <div className="content" id="noteikumi">
      <div>
        <h2>Likumi un dokumenti</h2>
      </div>

      <div className="noteikumiBlock">
        {links.map((link, index) => (
          <button
            key={index}
            className="noteikumiPdf"
            onClick={() => setSelectedUrl(link.url)}
          >
            {link.label}
          </button>
        ))}
      </div>

      {selectedUrl && (
        <div className="iframeContainer" style={{ marginTop: '20px', width: '100%' }}>
          <iframe
            src={selectedUrl}
            title="Dokuments"
            width="100%"
            height="600px"
            style={{ border: '1px solid #ccc' }}
          />
        </div>
      )}
    </div>
  );
}
